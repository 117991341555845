import { Routes, Route } from 'react-router-dom';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import ClientiList from 'pages/clienti/List';
import ClientiView from 'pages/clienti/View';
import ClientiAdd from 'pages/clienti/Add';
import ClientiEdit from 'pages/clienti/Edit';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
const App = () => {
	return (
		<Routes>
			<Route element={<MainLayout />}>
				<Route path="/" element={<HomePage />} />
				<Route path="/home" element={<HomePage />} />
				

				{/* clienti pages routes */}
				<Route path="/clienti" element={<ClientiList />} />
				<Route path="/clienti/:fieldName/:fieldValue" element={<ClientiList />} />
				<Route path="/clienti/index/:fieldName/:fieldValue" element={<ClientiList />} />
				<Route path="/clienti/view/:pageid" element={<ClientiView />} />
				<Route path="/clienti/add" element={<ClientiAdd />} />
				<Route path="/clienti/edit/:pageid" element={<ClientiEdit />} />
			</Route>
			<Route exact element={<IndexLayout />}>
				<Route path="/*" element={<IndexPages />} />
				<Route path="/error/*" element={<ErrorPages />} />
			</Route>
		</Routes>
	);
}
export default App;
